import React from "react"
import Layout from "../components/Layout"
import {PageWrapper, PageContainer} from "../styles/GlobalStyles";
import AboutCornholeContent from "../constants/aboutCornhole";
import Seo from "../components/SEO";


const AboutGamePage = () => {

    return (
        <Layout>
            <Seo title="Apie Cornhole Žaidimą (Baggo) - Žaisk Cornhole Lietuvoje"
                 description="Cornhole (dar vadinamas Baggo arba kukurūzų futbolas) – iš JAV kilęs ypač populiarus lauko žaidimas su maišeliais, kuriuos reikia pataikyti į skylę ant lentos."
            />
            <PageWrapper>
                <PageContainer>
                    <AboutCornholeContent/>
                </PageContainer>
            </PageWrapper>
        </Layout>
    )
};

export default AboutGamePage;
